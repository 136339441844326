html, body {
  margin: 0;
  padding: 0;
  background: #111;
  color: #666;
  font-size: 18px;
  font-family: 'Roboto Mono', monospace;
}

h1, h2 {
  color: #006633;
  font-family: "VT323", monospace;
  text-transform: uppercase;
}

h1 a {
  color: #006633;
  text-decoration: none;
}

h1 {
  margin: 0 0 20px;
}

h2 {
  margin: 0 0 40px;
}

a {
  color: tomato;
}

pre {
  background-color: #333;
  padding: 20px;
  overflow: scroll;
  color: #999;
}

.App {
  padding: 30px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.App > header {
  margin-bottom: 40px;
}

.Blog > article {
  border-top: 1px solid #006666;
  padding-bottom: 20px;
}

.Social {
  display: flex;
  justify-content: center;
}

.Social > div {
  margin: 0 5px;
}

.Social svg {
  width: 20px;
}

.Social svg path {
  fill: #009688;
}

.Social a:hover svg path {
  fill: lime;
}

.Stuff > div,
.Talks > div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 40px;
}

.Stuff > div > a,
.Talks > div > a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: transparent;
  width: 200px;
  height: 110px;
  background: tomato;
  margin: 0 10px 20px;
  background-size: cover;
  background-position: center;
  padding: 5px;
}

.Stuff > div > a:hover,
.Talks > div > a:hover {
  color: #ccc;
  background-image: none !important;
}

.Post {
  max-width: 50em;
  margin: 0 auto;
  text-align: left;
}

.Post img {
  width: 100%;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}
